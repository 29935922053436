import React, { createContext, useState, useEffect } from 'react';

const DataContext = createContext();

const DataProvider = ({ children }) => {
    const [data, setData] = useState([]);
    const [categories, setCategories] = useState([]);
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [cartItems, setCartItems] = useState(getCartItems());
    const [subTotal, setSubtotal] = useState(cartItems.reduce((accumulator, item) => (accumulator + item.price * item.quantity), 0).toFixed(2));
    const [hst, setHst] = useState((cartItems.reduce((accumulator, item) => (accumulator + item.price * item.quantity), 0) * 0.13).toFixed(2));
    const [grandTotal, setGrandTotal] = useState((cartItems.reduce((accumulator, item) => (accumulator + item.price * item.quantity), 0) * 1.13).toFixed(2));

    function getCartItems() {
        const cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
        const filteredCartItems = cartItems.filter(item => item.id !== 'XPCZVPUZZWATLMP2ANE6NL2C');
        return filteredCartItems;
    }

    const fetchData = async () => {
        try {
            const [res1, res2, res3] = await Promise.all([
                fetch('https://northshoresoapworks.com/getItems.php'),
                fetch('https://northshoresoapworks.com/getCategories.php'),
                fetch('https://northshoresoapworks.com/getOptions.php')
            ]);

            let data = await res1.json();
            if (!data) {
                data = {
                    "objects": [],
                    "related_objects": [],
                };
            }
            const categories = await res2.json();
            const options = await res3.json();

            setData(data);
            setCategories(categories);
            setOptions(options);

            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <DataContext.Provider value={{ data, categories, options, cartItems, setCartItems, subTotal, setSubtotal, hst, setHst, grandTotal, setGrandTotal, loading }}>
            {children}
        </DataContext.Provider>
    );
};

export { DataContext, DataProvider };